<script>
export default {
  name: 'HrbrCkeditorShareModalUserItem',

  props: {
    user: {
      type: Object,
    },
    initials: {
      type: String,
    },
    icon: {
      type: String,
    },
    roleText: {
      type: String,
    },
    roleDisplayOnly: {
      type: Boolean,
    },
  },

  methods: {
    disableUserHighlight() {
      if (this.user.highlight) {
        setTimeout(() => {
          this.user.highlight = false;
        }, 500);
      }
    },
  },

  mounted() {
    this.disableUserHighlight();
  },
};
</script>

<template>
  <div
    :class="{
      'ckeditor-sharing-modal-user-item': true,
      'user-item-owner': user.role === 'owner',
      'role-updated': user.roleUpdated,
      'new-user-highlight': user.highlight,
    }">
    <div class="user-details">
      <!-- profile picture or initials fallback -->
      <img
        v-if="user.profileImageUrl"
        class="profilePicture"
        :src="user.profileImageUrl" />

      <div v-else class="profilePicture">
        <p>{{ initials }}</p>
      </div>

      <!-- email and (username or em-dash) -->
      <div>
        <p :style="{ fontWeight: 700 }">{{ user.name || '-' }}</p>
        <p>{{ user.email }}</p>
      </div>
    </div>

    <!-- user role -->
    <div class="user-role">
      <!-- user role -->
      <p v-if="user.role === 'owner'" :style="{ fontWeight: 700 }">Owner</p>

      <p
        v-else-if="roleDisplayOnly"
        :style="{ textTransform: 'Capitalize', display: 'flex', alignItems: 'center' }">
        <i
          :class="'fas fa-' + user.icon + ' column'"
          :style="{ fontSize: '12px', opacity: 0.5 }"></i>
        {{ roleText }}
      </p>

      <b-select
        v-else
        placeholder="Role"
        @input="user.roleUpdated = true"
        v-model="user.role"
        :icon="icon || null"
        icon-pack="fas">
        <option value="writer">Editor</option>
        <option value="commentator">Commentor</option>
        <option value="reader">Reader</option>
      </b-select>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
.ckeditor-sharing-modal-user-item {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transition: 0.25s;
}

.ckeditor-sharing-modal-user-item.new-user-highlight {
  background-color: #e6f3ff;
}

.ckeditor-sharing-modal-user-item.user-item-owner {
  padding-right: 1em;
}

.ckeditor-sharing-modal-user-item .user-details {
  display: inline-flex;
  align-items: center;
}

.ckeditor-sharing-modal-user-item.user-role {
  display: inline-flex;
  align-items: center;
}

.ckeditor-sharing-modal-user-item.role-updated select {
  color: var(--main-primary-color);
}

.ckeditor-sharing-modal-user-item.role-updated.remove-access select {
  color: #f14668;
}

.ckeditor-sharing-modal-user-item .profilePicture {
  width: 40px;
  height: 40px;
  margin-right: 1em;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
