<script>
import HrbrCkeditorShareModalUserItem from './HrbrCkeditorShareModalUserItem.vue';

export default {
  name: 'HrbrCkeditorRemovedUsersShareModal',

  props: {
    removedUsers: {
      type: Array,
    },
  },

  components: {
    HrbrCkeditorShareModalUserItem,
  },

  methods: {
    onRemoveAccessBtnClick() {
      this.$emit('remove-access');
      this.close();
    },

    onGoBackButtonClick() {
      this.close();
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div id="hrbr-ckeditor-removed-users-share-modal-prompt" class="modal-card" ref="modal">
    <header class="modal-card-head">
      <p class="modal-card-title">Removing Access</p>
    </header>

    <section class="modal-card-body" style="padding: 1em" ref="modalCardBody">
      <p :style="{ marginBottom: '1em' }">
        Are you sure you want to remove access for the following people?
      </p>

      <div class="user-ctn">
        <div
          class="ckeditor-sharing-modal-user-item-ctn"
          v-for="user in removedUsers"
          :key="user.name + user.email">
          <HrbrCkeditorShareModalUserItem
            :role-display-only="true"
            :key="user.email"
            :user="user"
            :role-text="user.roleText"
            :initials="user.initials">
          </HrbrCkeditorShareModalUserItem>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="save-controls">
        <HrbrButton :button="{ text: 'Go back', styleType: 'secondary'}" @click="onGoBackButtonClick"/>
        <HrbrButton :button="{ text: 'Remove access', styleType: 'alert'}" @click="onRemoveAccessBtnClick"/>
      </div>
    </footer>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
#hrbr-ckeditor-removed-users-share-modal-prompt {
  min-height: 80%;
  z-index: 300;
  background-color: white;
  margin: auto;
  width: 60%;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .discard-changes {
  background-color: var(--main-primary-color);
  color: white;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item-ctn {
  display: flex;
  align-items: center;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item-owner {
  padding-right: 1em;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item-ctn:hover {
  background-color: #fafafa;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item .user-details {
  display: inline-flex;
  align-items: center;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item .user-role {
  display: inline-flex;
  align-items: center;
}

#hrbr-ckeditor-removed-users-share-modal-prompt
  .ckeditor-sharing-modal-user-item.role-updated
  select {
  color: var(--main-primary-color);
}

#hrbr-ckeditor-removed-users-share-modal-prompt
  .ckeditor-sharing-modal-user-item.role-updated.remove-access
  select {
  color: #f14668;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .ckeditor-sharing-modal-user-item .profilePicture {
  width: 40px;
  height: 40px;
  margin-right: 1em;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .user-ctn {
  max-height: 345px;
  overflow: scroll;
  margin-bottom: 1em;
  border-top: solid 1px #dadada;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .people-access-list {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #dadada;
  padding-bottom: 0.25em;
}

/* add email messaging */
#hrbr-ckeditor-removed-users-share-modal-prompt .help {
  color: #767676;
  margin-left: 0;
}

#hrbr-ckeditor-removed-users-share-modal-prompt textarea {
  padding-left: 1em;
}

#hrbr-ckeditor-removed-users-share-modal-prompt .modal-card-foot {
  display: flex;
  justify-content: flex-end;
}

#hrbr-ckeditor-removed-users-share-modal-prompt section {
  padding: 1.5em;
  font-size: 1.2em;
}
</style>
