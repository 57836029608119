<script>
export default {
  name: 'HrbrCkeditorInsertAnchorModal',

  props: {
    isEnabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      anchorName: '',
      closeButton: {
        text: 'Cancel',
        styleType: 'secondary',
        style: {
          width: '80px',
          marginRight: '6px',
        },
      },
      saveButton: {
        text: 'Done',
        styleType: 'primary',
        style: {
          width: '80px',
        },
      },
    };
  },

  methods: {
    handleInput(text) {
      this.anchorName = text.toLowerCase().replace(/[^a-z0-9]/g, '-');
    },

    saveAnchorName() {
      this.$emit('save', this.anchorName);
    },

    closeModal() {
      this.$emit('close');
    },

    saveAndClose() {
      this.saveAnchorName();
      this.closeModal();
    },
  },
};
</script>

<template>
  <div class="hrbr-ckeditor-insert-anchor-modal">
    <header class="modal-card-head">
      <p class="modal-card-title">Enter anchor name</p>
    </header>

    <section class="modal-card-body">
      <div class="media">
        <div class="media-content">
          <b-field :message="!isEnabled ? 'Please select text first' : ''">
            <b-input
              :disabled="!isEnabled"
              :type="isEnabled ? 'danger' : 'info'"
              style="margin-bottom: 15px"
              @input="handleInput"
              @keyup.native.enter="saveAndClose"
              v-model="anchorName"
              icon-pack="fal"
              icon="anchor">
            </b-input>
          </b-field>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <HrbrButton :button="closeButton" @click="closeModal"/>
      <HrbrButton :button="saveButton" @click="saveAndClose"/>
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-ckeditor-insert-anchor-modal {
  position: relative;
}
</style>
