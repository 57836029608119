<script>
export default {
  name: 'HrbrCkeditorUnsavedSharingChangesModal',

  methods: {
    onDiscardButtonClick() {
      this.$emit('close-parent');
      this.close();
    },

    onGoBackButtonClick() {
      this.close();
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div id="hrbr-ckeditor-close-share-modal-prompt" class="modal-card" ref="modal">
    <header class="modal-card-head">
      <p class="modal-card-title">Unsaved Changes</p>
    </header>

    <!-- modal content -->
    <section class="modal-card-body" style="padding: 1em" ref="modalCardBody">
      <p :style="{ marginBottom: '1em' }">
        You have some unsaved changes in your document sharing preferences!
      </p>
      <p>Go back and review or discard these changes?</p>
    </section>

    <footer class="modal-card-foot">
      <div class="save-controls">
        <HrbrButton :button="{ text: 'Go back', styleType: 'secondary'}" @click="onGoBackButtonClick"/>
        <HrbrButton :button="{ text: 'Discard changes', styleType: 'primary'}" @click="onDiscardButtonClick"/>
      </div>
    </footer>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
#hrbr-ckeditor-close-share-modal-prompt {
  min-height: 80%;
  z-index: 300;
  background-color: white;
  margin: auto;
  width: 60%;
}

#hrbr-ckeditor-close-share-modal-prompt .discard-changes {
  background-color: var(--main-primary-color);
  color: white;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item-ctn {
  display: flex;
  align-items: center;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item-owner {
  padding-right: 1em;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item-ctn:hover {
  background-color: #fafafa;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item .user-details {
  display: inline-flex;
  align-items: center;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item .user-role {
  display: inline-flex;
  align-items: center;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item.role-updated select {
  color: var(--main-primary-color);
}

#hrbr-ckeditor-close-share-modal-prompt .user-item.role-updated.remove-access select {
  color: #f14668;
}

#hrbr-ckeditor-close-share-modal-prompt .user-item .profilePicture {
  width: 40px;
  height: 40px;
  margin-right: 1em;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hrbr-ckeditor-close-share-modal-prompt .user-ctn {
  max-height: 345px;
  overflow: scroll;
  margin-bottom: 1em;
}

#hrbr-ckeditor-close-share-modal-prompt .people-access-list {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #dadada;
  padding-bottom: 0.25em;
}

/* add email messaging */
#hrbr-ckeditor-close-share-modal-prompt .help {
  color: #767676;
  margin-left: 0;
}

#hrbr-ckeditor-close-share-modal-prompt textarea {
  padding-left: 1em;
}

#hrbr-ckeditor-close-share-modal-prompt .modal-card-foot {
  display: flex;
  justify-content: flex-end;
}

#hrbr-ckeditor-close-share-modal-prompt section {
  font-size: 18px;
  padding: 1.5em;
}

.save-controls {
  display: flex;
  gap: 8px;
}

</style>
