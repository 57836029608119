<script>
import { COMPARISON_VIEWER_URL } from './lib/comparison';

export default {
  name: 'HrbrCkeditorDiffViewer',

  props: {
    comparisonId: {
      type: String,
    },
  },

  computed: {
    comparisonUrl() {
      return `${COMPARISON_VIEWER_URL}/${this.comparisonId}`;
    },
  },

  methods: {
    onCancelClick() {
      this.$emit('cancel-import');
      this.close();
    },

    onImportClick() {
      this.$emit('confirm-import');
      this.close();
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div
    class="comparison-viewer"
    :style="{ width: '100%', height: '100%', backgroundColor: 'white' }">
    <div
      class="comparison-viewer__header"
      :style="{ display: 'flex', justifyContent: 'right', padding: '1em' }">
      <b-button
        type="is-info"
        :style="{ margin: '0 0.5em', backgroundColor: '#2d71ad' }"
        @click="onImportClick">
        <i :style="{ marginRight: '1em' }" class="fa-light fa-file-arrow-up"></i> Import
      </b-button>

      <b-button
        @click="onCancelClick"
        type="is-info"
        :style="{ margin: '0 0.5em', backgroundColor: '#2d71ad' }">
        <i :style="{ marginRight: '1em' }" class="fa-light fa-x"></i> Cancel
      </b-button>
    </div>
    <iframe :src="comparisonUrl" style="width: 100%; height: 100%"></iframe>
  </div>
</template>

<style lang="postcss" scoped></style>
