<script>
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { publishEvent } from '@/utils/bus';
import { emailInputStoreRecentContacts } from '@/utils/helpers/email-input-helpers';
import HrbrCkeditorDocumentLock from './HrbrCkeditorDocumentLock.vue';
import HrbrCkeditorRemovedUsersShareModal from './HrbrCkeditorRemovedUsersShareModal.vue';
import HrbrCkeditorShareModalUserItem from './HrbrCkeditorShareModalUserItem.vue';
import HrbrCkeditorUnsavedSharingChangesModal from './HrbrCkeditorUnsavedSharingChangesModal.vue';
import { useCkeditorStore } from './stores/ckeditor-store';

export default {
  name: 'HrbrCkeditorShareModal',

  props: {
    fileDisplayId: {
      type: String,
    },
    linkDisplayId: {
      type: String,
    },
    agreementName: {
      type: String,
    },
    defaultRole: {
      type: String,
      default: 'writer',
    },
  },

  components: {
    HrbrCkeditorDocumentLock,
    HrbrCkeditorShareModalUserItem,
  },

  data() {
    return {
      storeRecentContacts: emailInputStoreRecentContacts,
      currentSearchString: null,
      addContactStatus: null,
      maxContactsLength: 500,
      displayName: '',
      emailInputData: null,
      emailMessage: null,
      notifyPeople: true,
      copyLinkMessaging: 'Copy link',
      saveUpdatesMessaging: 'Done',
      // use to block polling
      savingCollaboratorUpdates: false,
      shareableLinkText: null,
      sharedDocumentUrl: 'shared-agreement',
      loadedCollaborators: false,
      roleIcons: {
        writer: 'pen',
        commentator: 'comment',
        reader: 'eye',
      },
      roleDisplayText: {
        writer: 'editor',
        commentator: 'commentor',
        reader: 'reader',
      },
      // global state for closing modal
      closeAfterOperation: false,
      users: [],
    };
  },

  setup() {
    const ckeditorStore = useCkeditorStore();
    const draftsStore = useDraftsStore();
    return {
      ckeditorStore,
      draftsStore,
    };
  },

  computed: {
    usersHaveBeenUpdated() {
      return this.getRemovedUsers().length || this.getUpdatedUsers().length;
    },

    userListIsEmpty() {
      return (
        !this.users.length ||
        this.getRemovedUsers().length === this.users.length
      );
    },
    
    draftId() {
      const draft = this.draftsStore.findDraftByFileId(this.fileDisplayId);
      return draft ? draft.id : null;
    },

    copyLinkButton() {
      return {
        text: 'Copy link',
        iconLeft: 'fa-light fa-link',
        style: {
          marginRight: '.5em'
        }
      }
    },

    closeButton() {
      return {
        text: 'Close',
        style: {
          width: '80px',
          marginRight: '6px',
        },
      };
    },

    saveButton() {
      return {
        text: this.saveUpdatesMessaging,
        disabled: !this.usersHaveBeenUpdated,
        styleType: 'primary',
        style: {
          width: '80px',
        }
      }
    }
  },

  methods: {
    onDocumentLockUpdate(lockState) {
      this.$emit('document-lock-update', lockState);
    },

    getUserInitials(user) {
      if (!user.name) {
        return user.email[0].toUpperCase();
      }
      const fullName = user.name.split(' ');
      const first = fullName[0][0];
      const last = fullName[1] ? fullName[1][0] : '';
      return first.toUpperCase() + last.toUpperCase();
    },

    isValidEmail(email) {
      const successConditions = [
        [email && email.includes('@') && email.includes('.'), 'Please enter a valid email.'],
        [!this.users.find((u) => u.email === email), 'Email already in list.'],
        [this.users.length < this.maxContactsLength, 'At maximum number of contacts.'],
      ];

      let success = true;
      let successMessage = null;

      successConditions.forEach((pair) => {
        if (!success) return;
        if (!pair[0]) {
          success = false;
          successMessage = pair[1];
        }
      });
      return [success, successMessage];
    },

    captureEnteredEmail(newEmail) {
      if (!newEmail) return;
      setTimeout(() => (this.currentSearchString = null), 100);

      // if user was recently removed, add back in
      const existingUser = this.users.find((u) => u.email === newEmail && u.removed);
      if (existingUser) {
        existingUser.removed = false;
        return;
      }

      const validEmail = this.isValidEmail(newEmail);
      if (!validEmail[0]) {
        this.setAddContactStatus(validEmail[1]);
        return;
      }

      let name = null;
      let profileImageUrl = null;
      if (this.emailInputData != null) {
        name = this.emailInputData.emailsToNames[newEmail] || null;
        profileImageUrl = this.emailInputData.emailsToProfileImageUrls[newEmail] || null;
      }

      this.users.push({
        name: name || null,
        email: newEmail,
        profileImageUrl: profileImageUrl || null,
        role: this.defaultRole,
        roleUpdated: false,
        newUser: true,
        removed: false,
        highlight: true,
      });
    },

    markUserAsRemoved(user) {
      // if new user, don't record removal
      user.removed = true;
      this.$forceUpdate();
    },

    removeUser(user) {
      this.users = this.users.filter((u) => u.email !== user.email);
    },

    setAddContactStatus(message) {
      this.addContactStatus = message;
      setTimeout(() => (this.addContactStatus = null), 2000);
    },

    copyLinkToClipboard() {
      this.copyLinkMessaging = 'Copying...';
      navigator.clipboard.writeText(this.shareableLinkText).then(() => {
        this.copyLinkMessaging = 'Copied!';
        setTimeout(() => (this.copyLinkMessaging = 'Copy link'), 1000);
      });
    },

    getUpdatedUsers() {
      return this.users.filter((u) => u.newUser || u.roleUpdated);
    },

    getRemovedUsers() {
      return this.users.filter((u) => u.removed);
    },

    getHiddenUsers() {
      // TODO HIDDEN USERS
      // return this.users.filter((u) => u.hidden);
      return [];
    },

    removeUserAccessCallback() {
      this.closeAfterOperation = true;

      this.users = this.users.filter((u) => !u.removed);
      this.resetUserUpdates();
      this.saveButtonClickHandler();
    },

    resetUserUpdates() {
      this.users.forEach((u) => {
        u.roleUpdated = false;
        u.newUser = false;
      });
    },

    closeButtonClickHandler() {
      // check for pending saved changes
      const updates = this.getUpdatedUsers();
      const removedUsers = this.getRemovedUsers();
      if (!updates.length && !removedUsers.length) {
        this.closeModal();
        return;
      }

      this.$buefy.modal.open({
        parent: this,
        component: HrbrCkeditorUnsavedSharingChangesModal,
        events: {
          'close-parent': () => this.$emit('close'),
        },
        hasModalCard: false,
        fullScreen: false,
      });
    },

    async saveButtonClickHandler() {
      this.savingCollaboratorUpdates = true;
      const removedUsers = this.getRemovedUsers();

      // no removed users, no prompt
      if (!removedUsers.length) {
        this.resetUserUpdates();
        this.saveUpdatesMessaging = 'Saving...';

        const userEmailsByRole = (role) =>
          this.users.filter((u) => u.role === role).map((u) => u.email);

        // update recent contacts
        const emailsArray = [
          ...userEmailsByRole('writer'),
          ...userEmailsByRole('commentator'),
          ...userEmailsByRole('reader'),
        ];
        if (this.emailInputData != null) {
          this.storeRecentContacts(
            emailsArray,
            this.emailInputData.emailsToNames,
          );
          // emit recent contacts for parent
          this.$emit(
            'update-recent-contacts',
            emailsArray.map((email) => ({
              email,
              name: this.emailInputData.emailsToNames[email] || null,
            })),
          );
        }

        // make document sharing save request
        const respData = await this.ckeditorStore.saveCkeditorDocumentSharing({
          fileDisplayId: this.fileDisplayId,
          writers: userEmailsByRole('writer'),
          commentators: userEmailsByRole('commentator'),
          readers: userEmailsByRole('reader'),
          message: this.emailMessage,
          skipnotify: !this.notifyPeople,
          documentTitle: this.agreementName,
          linkDisplayId: this.linkDisplayId,
        });

        const { state, message } = respData;

        // failure - break early
        if (!state || state !== 'SUCCESS') {
          this.$buefy.toast.open({
            message: 'Something went wrong! Failed to save changes',
            type: 'is-danger',
            position: 'is-top',
            duration: 6000,
          });

          this.savingCollaboratorUpdates = false;
          this.saveUpdatesMessaging = 'Done';

          const logMessage = message ? `saveCkeditorDocumentSharing failed - ${message}` : 'saveCkeditorDocumentSharing failed - no message returned';
          const logError = new Error(logMessage);
          Sentry.captureException(logError);
          throw logError;
        }

        // success
        this.$emit('updated-user-access', this.users);
        this.saveUpdatesMessaging = 'Saved!';

        // cleanup
        this.savingCollaboratorUpdates = false;
        setTimeout(() => {
          this.saveUpdatesMessaging = 'Done';
          this.closeModal();
        }, 1000);
      } else {
        // removed users, prompt with list of removed
        this.$buefy.modal.open({
          parent: this,
          component: HrbrCkeditorRemovedUsersShareModal,
          events: {
            'remove-access': this.removeUserAccessCallback,
          },
          props: {
            removedUsers: this.getRemovedUsers().map((u) => ({
              name: u.name,
              email: u.email,
              profileImageUrl: u.profileImageUrl,
              initials: this.getUserInitials(u),
              icon: this.roleIcons[u.role],
              role: u.role,
              roleText: this.roleDisplayText[u.role],
            })),
          },
          hasModalCard: false,
          fullScreen: false,
        });
      }

      publishEvent('drafts:share-settings-updated', this.draftId);
    },

    async loadCollaboratorsData(callback = (emailData) => null) {
      try {
        const respData = await this.ckeditorStore.loadCkeditorDocumentSharing({
          fileDisplayId: this.fileDisplayId,
        });
        if (!respData) return;
        const { collaborators } = respData;
        const { writer, commentator, reader } = collaborators;
        const emailByRole = {
          writer,
          commentator,
          reader,
        };

        let loadedUserEmails = [...writer, ...commentator, ...reader];
        loadedUserEmails = loadedUserEmails.filter((email) =>
          Object.keys(emailByRole).find((roleKey) => emailByRole[roleKey].includes(email)),
        );
        const emailData = loadedUserEmails.map((email) => ({
          email,
          role:
            Object.keys(emailByRole).find((roleKey) => emailByRole[roleKey].includes(email)) ||
            null,
        }));
        callback(emailData);
      } catch (err) {
        console.error(err);
      }
    },

    // first collaborator data load
    async initialCollaboratorLoad(callback = () => {}) {
      await this.loadCollaboratorsData((emailData) => {
        emailData.forEach((user) => {
          if (!user.role) return;
          const profileImageUrl = this.emailInputData.emailsToProfileImageUrls[user.email] || null;
          this.users.push({
            name: this.emailInputData.emailsToNames[user.email] || null,
            email: user.email,
            profileImageUrl: profileImageUrl,
            role: user.role,
            roleUpdated: false,
            newUser: false,
            removed: false,
            highlight: false,
          });
        });
      });
      this.loadedCollaborators = true;
      callback();
    },

    // update collaborator data
    loadCollaboratorUpdates() {
      if (this.savingCollaboratorUpdates) return;
      this.loadCollaboratorsData((emailData) => {
        emailData.forEach((user) => {
          if (!user.role) return;
          // if user added upstream, push
          const existingUser = this.users.find((u) => u.email === user.email);
          if (!existingUser) {
            this.users.push({
              name: this.emailInputData.emailsToNames[user.email] || null,
              email: user.email,
              profileImageUrl: this.emailInputData.emailsToProfileImageUrls[user.email] || null,
              role: user.role,
              roleUpdated: false,
              newUser: false,
              removed: false,
              highlight: true,
            });
          } else if (existingUser.role !== user.role && !existingUser.roleUpdated) {
            // get upstream role updates
            existingUser.role = user.role;
            existingUser.highlight = true;
            setTimeout(() => (existingUser.highlight = false), 500);
          }
        });

        // if user deleted upstream, remove
        this.users
          .filter((user) => !user.newUser)
          .forEach((user) => {
            const oldUser = emailData.find((u) => u.email === user.email);
            if (!oldUser) {
              this.removeUser(user);
            }
          });
      });
    },

    initLoadCollaboratorsPolling() {
      this.collaboratorsPollingId = setInterval(() => this.loadCollaboratorUpdates(), 5000);
    },

    onEmailDataLoad(emailData) {
      this.emailInputData = emailData;
      // first collaborator load, followed by polling
      this.initialCollaboratorLoad(this.initLoadCollaboratorsPolling);
    },

    closeModal() {
      this.$emit('close');
    },

    setShareableLink() {
      this.shareableLinkText = `${window.location.protocol}//${window.location.host}/${this.sharedDocumentUrl}?docId=${this.fileDisplayId}`;
      if (this.linkDisplayId) {
        this.shareableLinkText += `&link_display_id=${this.linkDisplayId}`;
      }
    },

    setDisplayName() {
      this.displayName = this.agreementName || 'Agreement';
      const nameLengthLimit = 60;
      if (this.displayName.length > nameLengthLimit) {
        this.displayName = this.displayName.slice(0, nameLengthLimit) + '...';
      }
    },
  },

  mounted() {
    this.setShareableLink();
    this.setDisplayName();
  },

  beforeDestroy() {
    clearInterval(this.collaboratorsPollingId);
  },
};
</script>

<template>
  <div id="hrbr-ckeditor-share-modal" class="modal-card" ref="modal">
    <header class="modal-card-head">
      <p
        v-if="agreementName"
        :style="{ fontSize: displayName.length > 40 ? '1em' : '1.5em' }"
        class="modal-card-title"
        :title="displayName">
        Share {{ displayName }}
      </p>

      <p v-else class="modal-card-title">Share agreement</p>
    </header>

    <!-- modal content -->
    <section class="modal-card-body" style="padding: 1em" ref="modalCardBody">
      <b-field :message="addContactStatus">
        <EmailInput
          :style="{ marginBottom: '1em' }"
          placeholder="Add collaborators"
          v-model="currentSearchString"
          :email-store="currentSearchString"
          :data-store="emailInputData"
          :results-limit="30"
          :recents-tags-limit="5"
          :show-self-in-recents="false"
          @email-load-done="onEmailDataLoad"
          @profile-picture-load-done="(emailData) => (emailInputData = emailData)"
          @email-input-select="captureEnteredEmail"
          @email-input-enter="captureEnteredEmail"
          @email-input-blur="captureEnteredEmail"
          @email-input-recent-tag-select="captureEnteredEmail"></EmailInput>
      </b-field>

      <div class="people-access-list">
        <p :style="{ fontWeight: 700 }">People with access</p>
        <HrbrCkeditorDocumentLock
          :fileVersionDisplayId="fileDisplayId"
          @document-lock-update="onDocumentLockUpdate">
        </HrbrCkeditorDocumentLock>
      </div>

      <div class="user-ctn">
        <!-- document owner -->
        <div class="user-item-ctn">
          <HrbrCkeditorShareModalUserItem
            v-for="user in users.filter((user) => user.role === 'owner')"
            :key="user.email"
            :user="user"
            :initials="getUserInitials(user)">
          </HrbrCkeditorShareModalUserItem>
        </div>

        <!-- all other users -->
        <!-- empty users (first share or user removed all) -->
        <div v-if="!loadedCollaborators">
          <p :style="{ opacity: 0.5, textAlign: 'center', marginTop: '1em', fontStyle: 'italic' }">
            Loading collaborators...
          </p>
        </div>
        <div v-else-if="userListIsEmpty">
          <p :style="{ opacity: 0.5, textAlign: 'center', marginTop: '1em', fontStyle: 'italic' }">
            Only you have access to this document.
          </p>
        </div>
        <div
          v-else
          v-for="user in users.filter((u) => !u.removed && u.role !== 'owner').reverse()"
          :key="user.name + user.email"
          :class="{ 'user-item-ctn': true, 'new-user-highlight': user.newUser }">
          <HrbrCkeditorShareModalUserItem
            :key="user.email"
            :icon="roleIcons[user.role]"
            :user="user"
            :initials="getUserInitials(user)">
          </HrbrCkeditorShareModalUserItem>

          <!-- remove user button -->
          <i
            v-if="user.role !== 'owner'"
            class="fas fa-x column"
            @click="markUserAsRemoved(user)"
            style="cursor: pointer; font-size: 12px"></i>
        </div>
      </div>

      <b-field>
        <b-checkbox v-model="notifyPeople">Notify people</b-checkbox>
      </b-field>
      <b-field>
        <b-input
          v-model="emailMessage"
          maxlength="500"
          placeholder="Message"
          type="textarea"></b-input>
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <div class="copy-controls">
        <HrbrButton :button="copyLinkButton" @click="copyLinkToClipboard"/>
      </div>
      <div class="save-controls">
        <HrbrButton :button="closeButton" @click="closeButtonClickHandler"/>
        <HrbrButton :button="saveButton" @click="saveButtonClickHandler"/>
      </div>
    </footer>
    <button type="button" class="modal-close is-large" @click="closeModal" />
  </div>
</template>

<style>
#hrbr-ckeditor-share-modal {
  min-height: 80%;
  z-index: 300;
  background-color: white;
  margin: auto;
}

#hrbr-ckeditor-share-modal .done-btn {
  background-color: var(--main-primary-color);
  color: white;
}

#hrbr-ckeditor-share-modal .user-ctn {
  max-height: 345px;
  overflow: scroll;
  margin-bottom: 1em;
}

#hrbr-ckeditor-share-modal .user-item-ctn {
  display: flex;
  align-items: center;
}

#hrbr-ckeditor-share-modal .user-item-ctn:hover {
  background-color: #fafafa;
}

#hrbr-ckeditor-share-modal .people-access-list {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #dadada;
  padding-bottom: 0.25em;
}

/* add email messaging */
#hrbr-ckeditor-share-modal .help {
  color: #767676;
  margin-left: 0;
}

#hrbr-ckeditor-share-modal textarea {
  padding-left: 1em;
}

#hrbr-ckeditor-share-modal .modal-card-foot {
  display: flex;
  justify-content: space-between;
}

/* comments & mentions */
.ck-thread .mention {
  color: #005799;
  background: rgb(0 16 153 / 10%);
}
</style>
