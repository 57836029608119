<script>
import { useCkeditorStore } from './stores/ckeditor-store';

export default {
  name: 'HrbrCkeditorDocumentLock',

  props: {
    fileVersionDisplayId: {
      type: String,
    },
  },

  data() {
    return {
      sendingDocumentLockRequest: false,
      currentRole: 'unknown',
      userIsDocumentOwner: false,
      documentLockState: null,
      documentLockedMessaging: {
        locked: 'Locked',
        unlocked: 'Editable',
      },
      intervalId: null,
    };
  },

  setup() {
    const ckeditorStore = useCkeditorStore();
    return {
      ckeditorStore,
    };
  },

  methods: {
    async loadDocumentSettings() {
      if (this.userIsDocumentOwner || this.sendingDocumentLockRequest) {
        return;
      }

      try {
        const respData = await this.ckeditorStore.loadCkeditorDocumentSettings(
          this.fileVersionDisplayId,
        );
        if (!respData) throw new Error('Request error');

        this.documentLockState = respData.locked ? 'locked' : 'unlocked';
        this.userIsDocumentOwner = respData.isowner;

        // Check Role, unknown means no permissions
        if (respData.role === 'unknown') return false;
        // Set if never set before
        if (!this.currentRole) {
          this.currentRole = respData.role;
        }
        // Set if new value is diff
        if (respData.role !== this.currentRole) {
          this.currentRole = respData.role;
        }
      } catch (err) {
        console.error(err);
      }
    },

    loadDocumentSettingsInterval() {
      this.intervalId = setInterval(() => this.loadDocumentSettings(), 5000);
    },

    async lockCollaboration() {
      try {
        this.sendingDocumentLockRequest = true;
        const isCollabEnabled = this.documentLockState === 'unlocked';
        await this.ckeditorStore.lockCkeditorCollaboration({
          fileDisplayId: this.fileVersionDisplayId,
          isCollabEnabled,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.sendingDocumentLockRequest = false;
      }
    },

    onDocumentLockClick() {
      this.$emit('document-lock-update', this.documentLockState);
      this.lockCollaboration();
    },
  },

  mounted() {
    this.loadDocumentSettingsInterval();
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<template>
  <div class="document-lock">
    <!-- show switch, or... -->
    <div class="document-lock" v-if="documentLockState">
      <!-- lock/unlock document -->
      <b-switch
        :disabled="!userIsDocumentOwner"
        @input="onDocumentLockClick"
        type="is-success"
        v-model="documentLockState"
        true-value="unlocked"
        false-value="locked">
      </b-switch>
      {{ documentLockedMessaging[documentLockState] }}
    </div>
    <!-- ...show disabled switch while loading -->
    <div v-else>
      <b-switch :disabled="true" type="is-success"> </b-switch>
      <span :style="{ opacity: 0.5 }">
        <!-- Loading msg here -->
      </span>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>
