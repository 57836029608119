import Vue from 'vue';

const sendSignerRedliningCommentEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const linkdisplayid = urlParams.get('link_display_id');
  if (!linkdisplayid) return;

  const ckfileid = urlParams.get('docId');
  const orgsubdomain = urlParams.get('orgsubdomain');

  Vue.prototype.$harbourData.post('/signer-redlining-messaging', {
    linkdisplayid,
    ckfileid,
    orgsubdomain,
    operation: 'comment',
  });
};

// CKEditor Plugin for various custom features.
// Fixing Author name in the comment/TCs
// Event listeners for the comment/TCs.
// Fetching the list of users for CK Mentions.
export default class CkeditorMetadataIntegration {
  // Each item format: {name: 'John Doe', email: 'johndoe@gmail.com'}
  static _ckMentionsUserList = [];

  constructor(editor) {
    this.editor = editor;
  }

  init() {
    const commentsRepository = this.editor.plugins.get('CommentsRepository');

    // Listen for new comments
    // @ref https://ckeditor.com/docs/ckeditor5/latest/api/module_comments_comments_commentsrepository-CommentsRepository.html#event-addComment
    commentsRepository.on('addComment', (evt, data) => {
      sendSignerRedliningCommentEmail();
      // fileDisplayId put in window by ckeditor modal
      const fileDisplayId = window.fileDisplayId;
      const linkDisplayId = window.linkDisplayId;

      console.info('addComment event:', evt, data);
      // Isolate to 1 event (this is fired off 3 times per new comment)
      if (!('authorId' in data)) {
        console.debug('No authorId found in new comment, skipping');
        return;
      }
      const activeThread = evt.source.activeCommentThread;

      if (!activeThread) {
        console.debug('No active thread found, skipping');
        return;
      }

      const threadComments = activeThread.comments._items;
      if (!threadComments || threadComments.length === 0) {
        console.debug('No comments found in thread, skipping');
        return;
      }

      // Emails come from mentions (directly in comment)
      const emailsSet = new Set(); // for mentions

      // If we have comments, loop through them and find emails (mentions)
      threadComments.forEach((comment) => {
        // Parse the comment as an HTML element
        const parser = new DOMParser();
        const ckMentionsDoc = parser.parseFromString(comment.content, 'text/html');
        const ckCommentMentions = ckMentionsDoc.getElementsByClassName('mention');
        // Check if we have any mentions
        if (!ckCommentMentions || ckCommentMentions.length === 0) {
          return;
        }
        // Loop through mentions and dedupe a list of people to notify
        Array.from(ckCommentMentions).forEach((mentionSpan) => {
          const possibleEmail = mentionSpan.innerText.slice(1);
          const match = CkeditorMetadataIntegration.ckMentionsUserList.find(
            (userEmail) => userEmail === possibleEmail,
          );
          if (match) emailsSet.add(match);
        });
      });

      // notify the thread creator
      const threadAuthorId = threadComments[0].author?.id;

      // cast to sets to arrays
      const mentionedEmails = Array.from(emailsSet);

      // for developer
      const description = [];
      if (mentionedEmails.length > 0) {
        description.push(`Notifying ${mentionedEmails.length} mentioned user(s)`);
      } else {
        description.push('No mentioned users to notify');
      }

      const lastComment = threadComments[threadComments.length - 1];
      const lastCommentAuthor = {
        id: lastComment.author.id,
        name: lastComment.author.name,
        img: lastComment.author.avatar,
      };
      const postData = {
        filedisplayid: fileDisplayId,
        mentionedemails: mentionedEmails,
        threadauthorid: threadAuthorId,
        lastcomment: {
          content: lastComment.content,
          author: lastCommentAuthor,
        },
        linkdisplayid: linkDisplayId,
      };
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
        credentials: 'include',
      };
      console.log(
        'documentsharing-event - Sending request. Details: ' + description.join('. '),
        postData,
      );
      fetch('documentsharing-event', config)
        .then((res) => res.json())
        .then((response) => {
          console.log('documentsharing-event - Returning response', response);
        })
        .catch((error) => {
          console.log('documentsharing-event - error:', error);
        });
    });

    commentsRepository.on('addCommentThread', (evt, data) => {
      console.debug('addCommentThread event:', evt, data);
    });
    commentsRepository.on('removeCommentThread', (evt, data) => {
      console.debug('removeCommentThread event:', evt, data);
    });
    commentsRepository.on('removeComment', (evt, data) => {
      console.debug('removeComment event:', evt, data);
    });
    commentsRepository.on('change:activeCommentThread', (eventInfo, name, value, oldValue) => {
      console.debug('change:activeCommentThread event:', eventInfo, name, value, oldValue);
    });
    commentsRepository.on('updateComment', (evt, data) => {
      console.debug('updateComment event:', evt, data);
    });
  }

  static get ckMentionsUserList() {
    return CkeditorMetadataIntegration._ckMentionsUserList;
  }

  static set ckMentionsUserList(val) {
    CkeditorMetadataIntegration._ckMentionsUserList = val;
  }
}
